import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import DeskNavLink from './DeskNavLink'
import logo from '../../../assets/img/web/twdLogoGreen.png'
import { FaArrowRight as ArrowRight } from 'react-icons/fa6'
import menSmiling from '../../../assets/img/web/blackMenSmiling.png'

interface DeskNavProps {
	navLinks: any
	className: string
}

const DeskNav = ({ navLinks, className }: DeskNavProps) => {
	const location = useLocation()
	const [activeSubLink, setActiveSubLink] = useState<any>()

	const pagesCTAs = {
		'/': { href: '/contact', text: 'Get in touch' },
		'/helfy-partners': { href: 'https://app.thewellbeingdoctors.com/auth', text: 'Log in' }
	}

	const currentPageCTA = pagesCTAs[location.pathname] || { href: '/contact', text: 'Get in touch' }

	useEffect(() => {
		const toggleSubLink = (e: MouseEvent) => {
			const deskNavElement = document.getElementById('deskNav')

			if (deskNavElement && !deskNavElement.contains(e.target as HTMLElement)) {
				setActiveSubLink(undefined)
			}
		}

		document.addEventListener('click', toggleSubLink)

		return () => {
			document.removeEventListener('click', toggleSubLink)
		}
	}, [activeSubLink])

	return (
		<nav className={`${className} mx-auto max-w-[110rem] px-8 pb-4 pt-6`}>
			<div className="flex items-center justify-between">
				<Link to="/" className="p-1" onClick={() => setActiveSubLink(undefined)}>
					<img className="w-[280px]" src={logo} alt="The Wellbeing Doctors Logo" />
				</Link>
				<div id="deskNav" className="flex z-50 items-center gap-6">
					{navLinks.map((navLink: any) => (
						<DeskNavLink
							key={navLink.name}
							navLink={navLink}
							setActiveSubLink={setActiveSubLink}
							activeSubLink={activeSubLink}
						/>
					))}

					{activeSubLink?.children?.length > 0 && (
						<div className="absolute z-10 top-[84px] w-screen left-0 shadow-lg border bg-white">
							<div className="max-w-[110rem] flex justify-between gap-4 mx-auto px-8 py-6">
								<div className="flex flex-col gap-2 w-1/2">
									{activeSubLink.children?.map((childLink: any) => (
										<Link
											key={childLink.to}
											to={childLink.to}
											className="text-slate-600 hover:text-black p-2"
											onClick={() => setActiveSubLink(undefined)}
										>
											{childLink.name}
										</Link>
									))}
								</div>
								<Link
									to="/cash-plan"
									className="flex-1 max-w-[550px] bg-slate-100 rounded-xl overflow-hidden"
									onClick={() => setActiveSubLink(undefined)}
								>
									<img
										className="h-[260px] w-full bg-blue-200 object-cover object-top"
										src={menSmiling}
										alt="people smiling"
									/>
									<div className="flex justify-between gap-2 px-4 py-4">
										<div>
											<p className="font-medium text-xl">Got a cash plan?</p>
											<p className="text-lg text-slate-700">Access our doctors completely free</p>
										</div>
										<ArrowRight className="text-xl mt-auto" />
									</div>
								</Link>
							</div>
						</div>
					)}

					<a
						href={currentPageCTA.href}
						target="_blank"
						rel="noreferrer"
						className="text-[#2BFF89] bg-[#173F47] font-medium rounded-full text-sm px-6 py-3 text-center h-fit hover:text-[#173F47] hover:bg-[#2BFF89]"
						onClick={() => setActiveSubLink(undefined)}
					>
						{currentPageCTA.text}
					</a>
				</div>
			</div>
		</nav>
	)
}

export default DeskNav
