import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoMdArrowRoundBack, IoMdClose } from 'react-icons/io'
import { RxHamburgerMenu } from 'react-icons/rx'
import PhoneNavLink from './PhoneNavLink'
import { FaArrowRight as ArrowRight } from 'react-icons/fa6'
import menSmiling from '../../../assets/img/web/blackMenSmiling.png'
import logo from '../../../assets/img/web/twdLogoGreen.png'

interface PhoneNavProps {
	navLinks: any
	className: string
}

const PhoneNav = ({ navLinks, className }: PhoneNavProps) => {
	const [showNav, setShowNav] = useState<boolean>(false)
	const [activeSubLink, setActiveSubLink] = useState<any>()

	return (
		<nav
			className={`${className} flex items-center justify-between px-3 sm:px-5 md:px-6 pb-2 pt-4`}
		>
			<Link to="/" className="p-2">
				<img className="w-[270px]" src={logo} alt="The Wellbeing Doctors Logo" />
			</Link>

			<button onClick={() => setShowNav(true)} className="p-2">
				<RxHamburgerMenu size={30} className="text-black" />
			</button>

			<div
				className={`${
					showNav ? 'block' : 'hidden'
				} fixed z-50 top-0 left-0 w-full h-[100vh] bg-white overflow-y-auto`}
			>
				<div className="flex pb-6 pt-4 px-3 sm:px-5 md:px-6">
					<Link to="/" className="p-2">
						<img className="w-[270px]" src={logo} alt="The Wellbeing Doctors Logo" />
					</Link>

					<button onClick={() => setShowNav(false)} className="ml-auto p-2">
						<IoMdClose size={30} className="text-gray-800" />
					</button>
				</div>

				{!activeSubLink &&
					navLinks.map((navLink: any) => (
						<PhoneNavLink
							key={navLink.name}
							navLink={navLink}
							setActiveSubLink={setActiveSubLink}
							setShowNav={setShowNav}
						/>
					))}

				{activeSubLink?.children?.length > 0 && (
					<div className="flex flex-col">
						<button
							onClick={() => setActiveSubLink(undefined)}
							className="flex items-center text-xl w-full bg-slate-100 rounded-md text-slate-700 py-2 px-6 sm:px-8 md:px-10 mb-4"
						>
							<IoMdArrowRoundBack className="mr-2" />
							{activeSubLink.name}
						</button>
						{activeSubLink.children.map((childLink: any) => (
							<Link
								key={childLink.to}
								to={childLink.to}
								className="text-xl text-slate-600 hover:text-black py-2 px-6 sm:px-8 md:px-10 mb-4"
								onClick={() => setShowNav(false)}
							>
								{childLink.name}
							</Link>
						))}
					</div>
				)}

				<Link
					to="/cash-plan"
					className="m-6 sm:m-8 md:m-10 mt-8 mb-6 block bg-slate-100 rounded-xl overflow-hidden"
					onClick={() => setShowNav(false)}
				>
					<img
						className="h-[340px] w-full bg-blue-200 object-cover object-top"
						src={menSmiling}
						alt="people smiling"
					/>
					<div className="flex justify-between gap-2 px-4 py-4">
						<div>
							<p className="font-medium text-xl">Got a cash plan?</p>
							<p className="text-lg text-slate-700">Access our doctors completely free</p>
						</div>
						<ArrowRight className="text-xl mt-auto" />
					</div>
				</Link>
			</div>
		</nav>
	)
}

export default PhoneNav
