import { Link } from 'react-router-dom'
import moment from 'moment'
import twdLogoGreen from '../../assets/img/web/twdLogoGreen.png'
import privacyPolicy from '../../assets/terms/PrivacyPolicy.pdf'
import termsConditions from '../../assets/terms/TermsConditions.pdf'

const navigation = {
	social: [
		{
			name: 'YouTube',
			href: 'https://www.youtube.com/@behelfy',
			icon: (props: any) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
						clipRule="evenodd"
					/>
				</svg>
			)
		},
		{
			name: 'Email',
			href: 'mailto:edward@thewellbeingdoctors.com',
			target: '_blank',
			icon: (props: any) => (
				<svg fill="currentColor" viewBox="0 0 1850 1400" {...props}>
					<g id="packet">
						<path d="M1691,165.177C1691,73.952,1617.048,0,1525.823,0H165.177C73.952,0,0,73.952,0,165.177v1076.646   C0,1333.048,73.952,1407,165.177,1407h1360.646c91.225,0,165.177-73.952,165.177-165.177V165.177z M166.062,132h1361.057   c18.216,0,32.881,14.528,32.881,32.746v1.433L869.916,856.337c-8.417,8.417-18.208,9.675-23.318,9.675   c-5.11,0-14.934-1.258-23.353-9.675L133,166.085v-1.339C133,146.528,147.846,132,166.062,132z M1527.119,1275H166.062   c-18.216,0-33.062-15.084-33.062-33.301V352.961l596.826,596.816c31.198,31.197,72.684,48.376,116.803,48.376   c44.125-0.003,85.528-17.186,116.724-48.382L1560,353.054v888.645C1560,1259.916,1545.335,1275,1527.119,1275z" />
					</g>
					<g id="Layer_1" />
				</svg>
			)
		},
		{
			name: 'Instagram',
			href: 'https://www.instagram.com/behelfy',
			target: '_blank',
			icon: (props: any) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
						clipRule="evenodd"
					/>
				</svg>
			)
		},
		{
			name: 'Linkedin',
			href: 'https://www.linkedin.com/in/dr-edward-lynch/',
			target: '_blank',
			icon: (props: any) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						id="Path_2525"
						d="M23.002,21.584h0.227l-0.435-0.658l0,0c0.266,0,0.407-0.169,0.409-0.376c0-0.008,0-0.017-0.001-0.025   c0-0.282-0.17-0.417-0.519-0.417h-0.564v1.476h0.212v-0.643h0.261L23.002,21.584z M22.577,20.774h-0.246v-0.499h0.312   c0.161,0,0.345,0.026,0.345,0.237c0,0.242-0.186,0.262-0.412,0.262"
					/>
					<path
						id="Path_2520"
						d="M17.291,19.073h-3.007v-4.709c0-1.123-0.02-2.568-1.564-2.568c-1.566,0-1.806,1.223-1.806,2.487v4.79H7.908   V9.389h2.887v1.323h0.04c0.589-1.006,1.683-1.607,2.848-1.564c3.048,0,3.609,2.005,3.609,4.612L17.291,19.073z M4.515,8.065   c-0.964,0-1.745-0.781-1.745-1.745c0-0.964,0.781-1.745,1.745-1.745c0.964,0,1.745,0.781,1.745,1.745   C6.26,7.284,5.479,8.065,4.515,8.065L4.515,8.065 M6.018,19.073h-3.01V9.389h3.01V19.073z M18.79,1.783H1.497   C0.68,1.774,0.01,2.429,0,3.246V20.61c0.01,0.818,0.68,1.473,1.497,1.464H18.79c0.819,0.01,1.492-0.645,1.503-1.464V3.245   c-0.012-0.819-0.685-1.474-1.503-1.463"
					/>
					<path
						id="Path_2526"
						d="M22.603,19.451c-0.764,0.007-1.378,0.633-1.37,1.397c0.007,0.764,0.633,1.378,1.397,1.37   c0.764-0.007,1.378-0.633,1.37-1.397c-0.007-0.754-0.617-1.363-1.37-1.37H22.603 M22.635,22.059   c-0.67,0.011-1.254-0.522-1.265-1.192c-0.011-0.67,0.523-1.222,1.193-1.233c0.67-0.011,1.222,0.523,1.233,1.193   c0,0.007,0,0.013,0,0.02C23.81,21.502,23.29,22.045,22.635,22.059h-0.031"
					/>
				</svg>
			)
		}
	]
}

export default function Footer() {
	return (
		<footer className="bg-slate-50" aria-labelledby="footer-heading">
			<div className="mx-auto max-w-7xl px-8 md:px-10 lg:px-12 pt-10 pb-10">
				<div className="flex flex-wrap justify-between gap-14 mb-20 sm:gap-6 pt-14">
					<div className="w-full sm:w-1/2">
						<Link to="/">
							<img
								src={twdLogoGreen}
								alt="The Wellbeing Doctors"
								className="w-full max-w-[400px]"
							/>
						</Link>
						<p className="text-lg text-slate-900 mb-7 pt-3">
							Complete health for workplaces. We are here to give businesses the best workplace
							wellbeing in the world.
						</p>
						<Link
							to="/contact"
							className="text-slate-900 text-lg w-full sm:w-fit block text-center font-medium mt-4 rounded-full px-8 py-2 shadow-sm border-2 border-slate-300 hover:text-slate-500 hover:border-slate-500"
						>
							Contact us
						</Link>
					</div>
					<div className="flex flex-col flex-1 space-y-4 sm:text-right underline text-slate-900 items-center sm:items-end">
						<Link to="/" className="block w-fit p-2">
							For Businesses
						</Link>
						<Link to="/clinic" className="block w-fit p-2">
							For Clinicians
						</Link>
						<Link to="/helfy-partners" className="block w-fit p-2">
							For helfy®Partners
						</Link>
						<Link to="/about" className="block w-fit p-2">
							About us
						</Link>
					</div>
				</div>

				<div className="flex justify-between flex-wrap sm:flex-nowrap flex-col-reverse sm:flex-row gap-6">
					<span className="text-sm text-slate-500 mt-auto w-full sm:w-fit">
						&copy; Lynch Health Enterprises {moment().format('YYYY')}. All rights reserved |{' '}
						<a href={termsConditions} className="ml-1" target="_blank" rel="noopener noreferrer">
							{' '}
							T&C |
						</a>
						<a className="ml-1" href={privacyPolicy} target="_blank" rel="noopener noreferrer">
							{' '}
							Privacy policy{' '}
						</a>
					</span>
					<div className="flex space-x-6 mx-auto sm:mx-0">
						{navigation.social.map((item) => (
							<a
								key={item.name}
								href={item.href}
								target={item.target}
								className="text-slate-500 hover:text-slate-900"
							>
								<item.icon className="size-12" aria-hidden="true" />
							</a>
						))}
					</div>
				</div>
			</div>
		</footer>
	)
}
