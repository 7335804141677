// NOTE: hooks
import React, { lazy, ReactNode, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
// NOTE: components
import Loading from './components/ui/TWDLoading'
import Layout from './components/ui/Layout'
// import Layout from './components/website/Layout'
// NOTE pages
const Home = lazy(() => import('./pages/Home'))
// const Explore = lazy(() => import('./pages/Explore'))
// const Blog = lazy(() => import('./pages/Blog'))
const Errors = lazy(() => import('./pages/404'))
const Contact = lazy(() => import('./pages/Contact'))
const Package = lazy(() => import('./pages/Package'))
const HealthDays = lazy(() => import('./pages/HealthDays'))
const Clinic = lazy(() => import('./pages/Clinic'))
const HelfyPartners = lazy(() => import('./pages/HealfyPartners'))
const Diagnostics = lazy(() => import('./pages/Diagnostics'))
const HealthCash = lazy(() => import('./pages/HealthCash'))
const AboutUs = lazy(() => import('./pages/AboutUs'))

interface LoadPageProps {
	page: ReactNode
}

const LoadPage = ({ page }: LoadPageProps) => {
	return <Suspense fallback={<Loading />}>{page}</Suspense>
}

const App = () => {
	const routing = useRoutes([
		{
			element: <Layout />,
			children: [
				{
					index: true,
					element: <LoadPage page={<Home />} />
				},
				{
					path: 'contact',
					element: <LoadPage page={<Contact />} />
				},
				{
					path: 'lunch-learn',
					element: <LoadPage page={<Package />} />
				},
				{
					path: 'health-days',
					element: <LoadPage page={<HealthDays />} />
				},
				{
					path: 'clinic',
					element: <LoadPage page={<Clinic />} />
				},
				{
					path: 'helfy-partners',
					element: <LoadPage page={<HelfyPartners />} />
				},
				{
					path: 'diagnostics',
					element: <LoadPage page={<Diagnostics />} />
				},
				{
					path: 'cash-plan',
					element: <LoadPage page={<HealthCash />} />
				},
				{
					path: 'about',
					element: <LoadPage page={<AboutUs />} />
				},
				// {
				//     path: 'blogs',
				//     element: <Layout />,
				//     children: [
				//         {
				//             index: true,
				//             element: <LoadPage page={<Explore />} />
				//         },
				//         {
				//             path: ':id/:title',
				//             element: <LoadPage page={<Blog />} />
				//         },
				//     ],
				// },
				{
					path: '*',
					element: <LoadPage page={<Errors />} />
				}
			]
		}
	])

	return routing
}

export default App
